const colors = {
  // primary
  deepOcean: (getRGBCode?: boolean) => (getRGBCode ? '0,71,85' : '#004755'),
  darkOcean: (getRGBCode?: boolean) => (getRGBCode ? '3,109,130' : '#036D82'),
  ocean: (getRGBCode?: boolean) => (getRGBCode ? '0,142,170' : '#008EAA'),
  summerSky: (getRGBCode?: boolean) => (getRGBCode ? '128,188,200' : '#80BCC8'),
  oceanFoam: (getRGBCode?: boolean) => (getRGBCode ? '217,237,239' : '#D9EDEF'),
  iceBlue: (getRGBCode?: boolean) => (getRGBCode ? '240,246,248' : '#F0F6F8'),
  // secondary
  bark: (getRGBCode?: boolean) => (getRGBCode ? '96,71,49' : '#604731'),
  muleFawn: (getRGBCode?: boolean) => (getRGBCode ? '141,100,67' : '#8D6443'),
  sand: (getRGBCode?: boolean) => (getRGBCode ? '190,140,100' : '#BE8C64'),
  desert: (getRGBCode?: boolean) => (getRGBCode ? '220,200,175' : '#DCC8AF'),
  rodeoDust: (getRGBCode?: boolean) => (getRGBCode ? '245,234,227' : '#F5EAE3'),
  beach: (getRGBCode?: boolean) => (getRGBCode ? '250,247,241' : '#FAF7F1'),
  // grayscale
  black: (getRGBCode?: boolean) => (getRGBCode ? '25,21,20' : '#191514'),
  nightrider: (getRGBCode?: boolean) => (getRGBCode ? '51,51,51' : '#333333'),
  emperor: (getRGBCode?: boolean) => (getRGBCode ? '85,85,85' : '#555555'),
  gray: (getRGBCode?: boolean) => (getRGBCode ? '132,132,132' : '#848484'),
  silver: (getRGBCode?: boolean) => (getRGBCode ? '192,192,192' : '#C0C0C0'),
  gallery: (getRGBCode?: boolean) => (getRGBCode ? '234,234,234' : '#EAEAEA'),
  lightsmoke: (getRGBCode?: boolean) => (getRGBCode ? '247,247,247' : '#F7F7F7'),
  white: (getRGBCode?: boolean) => (getRGBCode ? '255,255,255' : '#FFFFFF'),
  // complimentary
  orangeGold: (getRGBCode?: boolean) => (getRGBCode ? '243,146,0' : '#F39200'),
  darkOrange: (getRGBCode?: boolean) => (getRGBCode ? '192,114,0' : '#C07200'),
  pine: (getRGBCode?: boolean) => (getRGBCode ? '49,102,60' : '#31663C'),
  meat: (getRGBCode?: boolean) => (getRGBCode ? '204,117,107' : '#CC756B'),
  violet: (getRGBCode?: boolean) => (getRGBCode ? '135,86,129' : '#875681'),
  // feedback
  green: (getRGBCode?: boolean) => (getRGBCode ? '0,160,75' : '#00A04B'),
  greenL1: (getRGBCode?: boolean) => (getRGBCode ? '155,205,160' : '#9BCDA0'),
  greenL2: (getRGBCode?: boolean) => (getRGBCode ? '217,241,228' : '#D9F1E4'),
  yellow: (getRGBCode?: boolean) => (getRGBCode ? '242,201,76' : '#F2C94C'),
  yellowL1: (getRGBCode?: boolean) => (getRGBCode ? '247,221,141' : '#F7DD8D'),
  yellowL2: (getRGBCode?: boolean) => (getRGBCode ? '251,236,193' : '#FBECC1'),
  red: (getRGBCode?: boolean) => (getRGBCode ? '222,73,83' : '#DE4953'),
  redL1: (getRGBCode?: boolean) => (getRGBCode ? '240,150,120' : '#F09678'),
  redL2: (getRGBCode?: boolean) => (getRGBCode ? '251,218,221' : '#FBDADD'),
  blue: (getRGBCode?: boolean) => (getRGBCode ? '47,128,237' : '#2F80ED'),
  blueL1: (getRGBCode?: boolean) => (getRGBCode ? '120,172,243' : '#78ACF3'),
  blueL2: (getRGBCode?: boolean) => (getRGBCode ? '203,223,250' : '#CBDFFA'),
};

const components = {
  primaryButtonBackgroundColor: () => colors.orangeGold(),
  primaryButtonTextColor: () => colors.white(),
  buttonOutline: () => `2px solid ${colors.summerSky()}`,
  secondaryButtonBackgroundColor: () => colors.white(),
  secondaryButtonTextColor: () => colors.nightrider(),
  secondaryButtonBorder: () => `2px solid ${colors.gray()}`,
  secondaryButtonBorderDisabled: () => `2px solid ${colors.silver()}`,
  divider: (getRGBCode?: boolean) => colors.ocean(getRGBCode),
  navbarFocus: (getRGBCode?: boolean) => colors.ocean(getRGBCode),
};

const breakpoints = {
  desktop: '1300px',
};

const theme = {
  breakpoints,
  colors: {
    ...colors,
    ...components,
  },
  components,
};

export default theme;
