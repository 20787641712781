import { ApplicationType, ProductType } from 'lib/types/user-types';

import { ApiProduct } from 'lib/cms/queries';
import { LabelTypeEnum } from 'lib/types/label';
import { create } from 'zustand';
import { immer } from 'zustand/middleware/immer';
import { createJSONStorage, persist } from 'zustand/middleware';
import { produce } from 'immer';

const GUID = [
  'fa685617-7046-4ac9-a949-3462f5e7bedb',
  'e84a82a7-527a-4f96-9a5f-08b681359a9a',
  'f80df00f-8d23-4c7a-bfee-96c0223ba840',
  'd4192ce9-2e72-494b-b271-51382f984e7c',
  'ac2ffe00-3a35-4b9b-921d-6ebf9db77f4d',
  'c8cf106c-eefa-4b80-a98f-bec92c0a365f',
  'e8cf1883-f6f6-4e27-b765-d690ce207431',
  '40c198a9-8238-453b-984c-26406af680f3',
  'c3c744c3-0b74-4fbd-8b1d-ff9a80c74aff',
  '94b7579a-6ec8-46ee-8842-20d17c6604d7',
  'd9566050-501b-4070-98d5-a7905eef2d10',
  'dcce77fd-c270-4c95-bbf5-b3cee0db7534',
  'fd6a1879-a8fe-4433-bd22-81de984ddb8d',
  '50e60b36-8a84-4256-b0d5-b3cbf4a1e406',
  '1502f956-5376-4859-8d6f-49a0a3336b2a',
  '5a0bb8b0-3c54-4e6d-9dee-b54561147566',
  '0ac7caae-4e67-401e-a6bd-f2b098a5a711',
  'eabcd761-a23c-4733-bebf-bbac87e7e9ed',
  '0f11425b-4a9b-43e5-b3c8-89389917d74a',
  '8663d80c-f50e-4d79-8f90-f31233a86cff',
];

const appId = [
  'zCG1/bkIhkChWY8wH5sG+A==',
  'ZQOEo3upUkaoDP7E20KJuA==',
  'AaaCAXdEyEOUwvJH/mG5Lg==',
  'q5o2jL1IakeDiMXbe8Wd1g==',
  'Uwbt4dnvIUOM0wi3rzkHww==',
  'fQZjsa59l0C225SOJfqW9A==',
  'Ib1dzdWKnkOJs9n9h6PFSw==',
  'DO/1++PSU0uAZWkO3ikfEA==',
  'd/LeAp6mmkaLAlJYZQpeXQ==',
  'NmFGBy5GQ0GQFf9MuFaE3Q==',
  'CFXWAI1JfEipn02JxGVMEQ==',
  'DLWea0qwaEuWK0gnM+Ha7A==',
  'FZmDUMc95UCNATyNzzQfCw==',
  'aFtyhV9jVkikXbiYTEzZ6w==',
  'E5rZjkTRE0iRkecQv0WB3A==',
  'lKKObEOCBUWfxTDpqQq7Uw==',
  '8CMM/6ghI0KAb3Gg7ZVzxA==',
  'Dv3N8NqVqUSJbiCAIDxifg==',
  'NnV2dadsTkOS6fHCsDFsAA==',
  'rZdqCmTdpk6Hj1bDCQa5FA==',
];

const TEST_USER = !!process.env.NEXT_PUBLIC_TEST_USER;
const targetUserSession = TEST_USER ? 'userSessionTest' : 'userSession';

export type FeatureToggleType = {
  id?: string;
  attributes: {
    name: string;
    payload: {
      data: unknown;
    };
    status: {
      [key: string]: boolean;
    };
    error?: string;
  };
};

export type OrganizationUserType = {
  name: string;
  email: string;
  role: string;
  status: string;
};

export type UserSessionType = {
  name?: string | null | undefined;
  email?: string | null | undefined;
  image?: string | null | undefined;
  applications: ApplicationType[];
  products: ProductType[];
  organization: {
    users: OrganizationUserType[];
  };
};

export interface StateTypes {
  locale: string;
  setLocale: (updateLocale: string) => void;
  apiProducts: ApiProduct[];
  featureToggles: FeatureToggleType[];
  strapiJWT: string;
  isAuth: boolean;
  consent: {
    cookies: {
      showDisclaimer: boolean;
      userValidated: boolean;
      nextjs: boolean;
    };
  };
  showSignUp: boolean;
  showLogin: boolean;
  userSessionTest: UserSessionType;
  userSession: UserSessionType;
  set: (fn: (state: StateTypes) => void) => void;
  createApplication: (values: { appName: string; appStatus: string }) => void;
  deleteApplication: (appName: string) => void;
}

export const useGlobalStore = create<StateTypes>()(
  persist(
    immer((set) => ({
      locale: '',
      setLocale: (updateLocale: string) => set({ locale: updateLocale }),
      apiProducts: [],
      businessObjectTypes: [],
      featureToggles: [],
      strapiJWT: '',
      isAuth: false,
      consent: {
        cookies: {
          showDisclaimer: true,
          userValidated: false,
          nextjs: false,
        },
      },
      showSignUp: false,
      showLogin: false,
      userSessionTest: {
        name: 'Daniel Gustavsson',
        email: 'enbrakompis@gmail.com',
        applications: [
          {
            name: 'My First App',
            createdAt: '2021-09-12',
            status: 'active',
            requests: 73465,
            appKey: '932abe73-552c-4661-8961-8e5203c94e5d',
            appClientId: 'QnNKarGQRUK9ADK6YLrvNA==',
          },
          {
            name: 'My Second App',
            createdAt: '2021-10-01',
            status: 'inactive',
            requests: 49752,
          },
          {
            name: 'My Third App',
            createdAt: '2021-10-14',
            status: 'active',
            requests: 0,
            appKey: 'cdb8d569-0e42-438c-a112-a73a904b27ef',
            appClientId: 'PrjVtzYdOE+iAP82nPehUg==',
          },
        ],
        products: [
          {
            apiProductID: 'beneficial-owner-monitoring',
            status: {
              test: true,
              production: true,
            },
            labels: [
              {
                text: 'test',
                type: LabelTypeEnum.highlight,
              },
              {
                text: 'production',
                type: LabelTypeEnum.highlight,
              },
            ],
          },
          {
            apiProductID: 'beneficial-owner-service',
            status: {
              test: true,
              production: false,
            },
            labels: [
              {
                text: 'test',
                type: LabelTypeEnum.highlight,
              },
            ],
          },
        ],
        organization: {
          users: [
            {
              name: 'John Doe',
              email: 'john.doe@uc.se',
              role: 'Developer',
              status: 'Active',
            },
            {
              name: 'Jane Doe',
              email: 'jane.doe@uc.se',
              role: 'Admin',
              status: 'Active',
            },
          ],
        },
      },
      userSession: {
        name: '',
        email: '',
        applications: [],
        products: [],
        organization: {
          users: [],
        },
      },
      set: (fn: (state: StateTypes) => void) => set(produce(fn)),
      createApplication: (values: { appName: string; appStatus: string }) => {
        set(
          produce((state: StateTypes) => {
            const newApplicationToAdd = {
              name: values.appName,
              status: values.appStatus,
              createdAt: new Date().toLocaleDateString(),
              requests: 0,
              appKey: values.appStatus === 'active' ? GUID.pop() : undefined,
              appClientId: values.appStatus === 'active' ? appId.pop() : undefined,
            };

            state[`${targetUserSession}`].applications = state[`${targetUserSession}`].applications.concat(newApplicationToAdd);
          })
        );
      },
      deleteApplication: (appName: string) => {
        set(
          produce((state: StateTypes) => {
            state[`${targetUserSession}`].applications = state[`${targetUserSession}`].applications.filter(
              (app: ApplicationType) => app.name !== appName
            );
          })
        );
      },
    })),
    {
      name: 'global-store',
      storage: createJSONStorage(() => sessionStorage),
    }
  )
);
