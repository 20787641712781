import './globals.scss';

import App, { AppContext, AppProps } from 'next/app';

import { SWRConfig } from 'swr';
import { Session } from 'next-auth';
import { SessionProvider } from 'next-auth/react';
import { ThemeProvider } from 'styled-components';
import { defaultFetcher } from 'lib/swr';
import dynamic from 'next/dynamic';
import enentoTheme from 'components/atomic-atoms/theme';
import { useEffect } from 'react';
import { useGlobalStore } from 'lib/store';

const Layout = dynamic(() => import('components/atomic-sections/Layout'));

export default function DeveloperPortalApp({ Component, pageProps }: AppProps<{ session: Session }>) {
  const set = useGlobalStore((state) => state.set);

  useEffect(() => {
    const setInitialStoreValues = () => {
      set((state) => {
        state.locale = 'en';
      });
    };

    setInitialStoreValues();
  }, [set]);

  return (
    <SessionProvider session={pageProps.session}>
      <SWRConfig
        value={{
          errorRetryInterval: 1000,
          errorRetryCount: 2,
          shouldRetryOnError: true,
          fetcher: defaultFetcher,
        }}
      >
        <ThemeProvider theme={enentoTheme}>
          <Layout>
            <Component {...pageProps} />
          </Layout>
        </ThemeProvider>
      </SWRConfig>
    </SessionProvider>
  );
}

DeveloperPortalApp.getInitialProps = async (appContext: AppContext) => {
  const appProps = await App.getInitialProps(appContext);

  return { ...appProps };
};
