export enum LabelTypeEnum {
  primary = 'primary',
  secondary = 'secondary',
  highlight = 'highlight',
}

export type LabelType = {
  type: LabelTypeEnum;
  text: string;
  TTL?: {
    startDate: string;
    endDate: string;
  };
  height?: string;
};
