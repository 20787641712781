import { GenericJSON } from 'lib/types';

interface defaultFetcherProps {
  endpoint: string;
  query: string;
  variables: GenericJSON;
}

export const defaultFetcher = async ({ endpoint, query, variables }: defaultFetcherProps) => {
  try {
    const body = variables ? JSON.stringify({ query, variables }) : JSON.stringify({ query });
    const options = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body,
    };

    const response = await fetch(endpoint, options);
    return (await response.json()) as GenericJSON;
  } catch (error) {
    console.error(error);
  }
};
